import {
  Button,
  Container,
  MantineProvider,
  NumberInput,
  SegmentedControl,
  Spoiler,
  Stack,
  Title,
  createTheme,
} from '@mantine/core';
import '@mantine/core/styles.css';
import classes from './App.module.css';
import { useEffect, useMemo, useState } from 'react';
import boom from './boom.mp3';
import boom_loud from './boom-loud.mp3';
import airhorn from './airhorn.mp3';
import useSound from 'use-sound';

const theme = createTheme({
  
});

function App() {
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [timeUnit, setTimeUnit] = useState('s');
  const [limits, setLimits] = useState({
    from,
    to,
  });
  const [playBoom] = useSound(boom);
  const [playBoomLoud] = useSound(boom_loud);
  const [playAirhorn] = useSound(airhorn);
  const COOLDOWN_TIME = 2

  const createTargetTime = () => {
    const delay = Number(
      (Math.random() * ((limits.from + limits.to) / 2)).toFixed(1)
    );
    const target = new Date().getTime() + (delay + limits.from + COOLDOWN_TIME) * 1000;

    return new Date(target);
  };
  const [targetTime, setTargetTime] = useState(createTargetTime());

  const [timeRemaining, setTimeRemaining] = useState(0);

  useEffect(() => {
    setTargetTime(createTargetTime());
  }, [limits]);

  useEffect(() => {
    if (limits.from === 0 && limits.to === 0) return;
    const loop = () => {
      let remaining = targetTime.getTime() - new Date().getTime();

      if (remaining < 0) {
        setTargetTime(createTargetTime());
        const sounds = [playBoom, playBoomLoud, playAirhorn];
        const sfx = sounds[Math.floor(Math.random() * sounds.length)];
        console.log(sfx);
        sfx();
      } else {
        setTimeRemaining(remaining);
      }

      animID = requestAnimationFrame(loop);
    };

    let animID = requestAnimationFrame(loop);

    return () => cancelAnimationFrame(animID);
  }, [targetTime]);

  const parsedTimeRemaining = useMemo(()=> {
    // working in ms, not s
    let inSeconds = timeRemaining / 1000
    if (inSeconds < 60) {
      return (inSeconds).toFixed(2) + " s"
    }
    let minutes = Math.floor(inSeconds / 60)
    let seconds = (inSeconds - (minutes * 60)).toFixed(1)
    return `${minutes}m ${seconds}s`
  }, [timeRemaining])

  return (
    <MantineProvider defaultColorScheme='dark'>
      <Container size={'xs'} py={'xl'}>
        <Stack>
          <Spoiler maxHeight={1} showLabel="Visa tid" hideLabel="Göm"></Spoiler>
          <Title order={1} className={classes.title} ta={'center'}>
            {parsedTimeRemaining}
          </Title>
          <SegmentedControl
            size="xs"
            data={[
              { label: 'Sekunder', value: 's' },
              { label: 'Minuter', value: 'm' },
            ]}
            value={timeUnit}
            onChange={(e) => setTimeUnit(e)}
          />
          <NumberInput
            label="Från"
            value={from}
            onChange={(e) => setFrom(Number(e))}
          />
          <NumberInput
            label="Till"
            value={to}
            onChange={(e) => setTo(Number(e))}
          />
          <Button
            onClick={() => {
              playBoomLoud();
              const tempFrom = timeUnit === "s" ? from : from * 60
              const tempTo = timeUnit === "s" ? to : to * 60
              setLimits({ from: tempFrom, to: tempTo });
            }}
          >
            Kör
          </Button>
        </Stack>
      </Container>
    </MantineProvider>
  );
}

export default App;
